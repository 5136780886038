import React, { useState } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'

import Button from '@components/button'
import InputText from '@components/input-text'
import InputTextarea from '@components/input-textarea'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = ({ inContainer, width, className }) => {
  const [formFields, setFormFields] = useState({})

  const handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': e.target.getAttribute('name'),
        ...formFields
      })
    })
      .then(() => navigate('/kiitos'))
      .catch(error => alert(error))

    e.preventDefault()
  }

  const handleChange = e =>
    setFormFields({ ...formFields, [e.target.name]: e.target.value })

  const { nimi = '', email = '', vapaaTeksti = '' } = formFields

  const renderForm = (
    <form
      className="mw6"
      onSubmit={handleSubmit}
      data-netlify="true"
      name="Yhteyslomake"
      method="post"
      netlify-honeypot="bot-field"
    >
      <InputText
        name="nimi"
        value={nimi}
        placeholder="Nimi"
        onChange={handleChange}
        required
      />
      <InputText
        type="email"
        name="email"
        value={email}
        placeholder="E-mail"
        onChange={handleChange}
        required
      />
      <InputTextarea
        name="vapaaTeksti"
        value={vapaaTeksti}
        onChange={handleChange}
        placeholder="Vapaa teksti"
      />
      <Button type="submit" className="w-100" size="md">
        Lähetä
      </Button>
    </form>
  )

  if (inContainer) {
    return (
      <div
        className={classNames(
          'form-shadow bg-white br4 overflow-hidden',
          className
        )}
        style={{ maxWidth: width }}
      >
        <div className="ttu f6 fw6 tc bg-green white pa4">Ota yhteyttä</div>
        <div className="pa4">{renderForm}</div>
      </div>
    )
  }

  return (
    <div style={{ maxWidth: width }} className={className}>
      {renderForm}
    </div>
  )
}

export default ContactForm
