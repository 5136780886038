import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '@components/layout'
import Title from '@components/title'
import ContactForm from '@components/contact-form'

const ContactUsPage = ({ uri }) => {
  return (
    <Layout currentLocation={uri}>
      <Helmet>
        <title>Yhteystiedot | Tuulia Kodit</title>
      </Helmet>
      <section className="section section--bg pb7-sm pb9-m pb10-l">
        <div className="container flex flex-wrap mb6-sm mb8">
          <div className="w-100-sm w-50 pr0-sm pr5 mb5-sm">
            <Title as="h1" align="left" className="mb4" noUnderline>
              Ota yhteyttä
            </Title>
            <p className="lh-text mb4">
              Koskeeko asiasi vikailmoituksia, oven avausta tai nimitietojen
              päivitystä?{' '}
              <a href="/asukkaalle/#yhteystiedot">Katso talosi huoltoyhtiön yhteystiedot</a>
            </p>
            <p className="lh-text mb6">
              Joudutko ilmoittamaan asumishäiriöstä tai onko sinulla kysyttävää taloyhtiön hallintaan liittyvistä asioista:
              <br />
              <a href="/asukkaalle/#yhteystiedot">Katso talosi isännöitsijän yhteystiedot</a>
            </p>

            <Title heading="h6" className="gray-blue mb5">
              Etsitkö asuntoa tai haluatko tietää lisää asuntokohteistamme?
            </Title>

            <ContactForm width="458px" />
          </div>

          <div className="w-100-sm w-50">
            <iframe
              className="w-100 h-100 border-0 h5-sm"
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1921.615912946556!2d26.031225116028725!3d61.20809056602114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469029cc8074880f%3A0x64e4d8848669ad24!2sLaaksokatu%202%2C%2018100%20Heinola!5e0!3m2!1sen!2sfi!4v1600432380130!5m2!1sen!2sfi"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </div>
        </div>

        <div className="container flex flex-wrap nb5-sm">
          <ContactItem title="Postiosoite">
            <p>
              Laaksokatu 2,
              <br /> 18100 Heinola
            </p>
          </ContactItem>

          <ContactItem title="Puhelinnumero">
            <a href="tel:0341089510" className="db">
              03-41089510
            </a>
          </ContactItem>

          <ContactItem title="Sähköposti">
            <a
              href="mailto:vuokrakodit@tuulicapital.fi"
              className="db break-word"
            >
              vuokrakodit@tuuliacapital.fi
            </a>
          </ContactItem>

          <ContactItem title="Aukioloajat">
            <p>Ma-Pe: 8-16</p>
          </ContactItem>
        </div>
      </section>
    </Layout>
  )
}

export default ContactUsPage

const ContactItem = ({ title, children }) => (
  <div className="flex-grow-1 w-50-sm mb5-sm pr4-sm">
    <Title heading="h6" noUnderline className="mb3-sm mb4">
      {title}
    </Title>
    {children}
  </div>
)
